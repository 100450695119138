import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreEight from "../../components/WhoWeAre/WhoWeAreEight";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";

import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";


const array=[
  {
    "id": 1,
    "title": "Ingeniería Industrial",
    "tagline": "",
    "image": "Servicios/ingInd2.jpg",
    "buttons": [
      {
        "id": 1
       
      }
    ]
  }
]

const ServiceSimple = () => (
  <Loader>
    <HeaderWithoutDrop />
    <HeroSliderTwo data={array} />
    {/*<PageTitleService title="Proteccion Civil" tagline="Ideas that soar" />*/}
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-20 col-sm-4">
           <ul>
             <li>Servicios de asesoría, consultoría, gestoría, para sus proyectos.</li>
             <li>Cambio a Gas combustible sus sistemas de combustión y sistemas de control de flama en : Hornos, Calderas, Secadoras, Etc. que utilicen combustibles líquidos y gaseosos.</li>
           </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <ul>
          <li>Insonorización acústica a cuartos, equipos o áreas completas.</li>
          <li>Calibración de Equipos de Medición con trazabilidad ante el CENAM.</li>
          </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
            <ul>
              <li>Diseño, Fabricación y Maquinado de piezas en acero al carbón, acero inoxidable, monel o el material que sus especificaciones requieran.</li>
              <li>Atención de visitas de inspección.</li>
              <li>Elaboración de Precios Unitarios.</li>
            </ul>
          </div>
         
        </div>
      </div>
    </section>
   

    <FooterOne />
  </Loader>
);

export default ServiceSimple;
