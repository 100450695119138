import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreEight from "../../components/WhoWeAre/WhoWeAreEight";
import HeroSections from "../../components/Hero/HeroSections";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";

const array=[
  {
    "id": 1,
    "title": "Proteccion Civil",
    "tagline": "",
    "image": "Servicios/proteccionCivil.jpg",
    "buttons": [
      {
        "id": 1
       
      }
    ]
  }
]


/*
const array={
  "id": 1,
  "title": "Proteccion Civil",
  "image": "Servicios/proteccionCivil.jpg",
  "buttons": [
    {
      "id": 1,
      "text": "WATCH OUR VIDEO",
      "link": "!#",
      "type": "btn-circle btn-color"
    }
  ]
};  */

const ServiceSimple = () => (
  <Loader>
    <HeaderWithoutDrop />
    <HeroSliderTwo data={array} />
    {/*<PageTitleService title="Proteccion Civil" tagline="Ideas that soar" />*/}
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-20 col-sm-4">
           <ul>
             <li>Elaboración de programas de Protección Civil.</li>
             <li>Actualización de programas de Protección Civil</li>
             <li>Elaboración de Documentación faltante</li>
             <li>Carta del tercero acreditado</li>
           </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <ul>
          <li>Simulacros</li>
          <li>Autorización de recipientes sujetos a presión</li>
          <li>Estudios de Medio Ambiente.</li>
          <li>Carta del tercero acreditado</li>
          </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <h7>CURSOS EN CAMPO O INSTALACIONES PROPIAS:</h7>
            <ol>
              <li>Multibrigadas</li>
              <li>Combate de incendios</li>
              <li>Primeros auxilios</li>
              <li>Evacuación</li>
              <li>Comunicación</li>
            </ol>
          </div>
         
        </div>
      </div>
    </section>
    

    <FooterOne />
  </Loader>
);

export default ServiceSimple;
