import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import dataCorporate from "../../data/Slider/corporate-business-data.json";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import HeroSliderSingle from "../../components/Hero/HeroSliderSingle";
import WhoWeAreThree from "../../components/WhoWeAre/WhoWeAreThree";
import OurTeamThree from "../../components/Team/OurTeamThree";
import TabsIconSection from "../../components/Tabs/TabsIconSection";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import BlogSlider from "../../components/Blog/BlogSlider";
import FooterOne from "../../components/Footer/FooterOne";
import HeadingSection from '../../components/HeadingSection/HeadingSection'
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import Portfolio from "../../components/Portfolio/Portfolio";

const CorporateBusiness = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderWithoutDrop />
      <HeroSliderSingle data={dataCorporate} />
      <WhoWeAreThree />
      {/*<Portfolio
        filter="true"
        layout="wide"
        columns="2"
        items="6"
        classAppend="pt-0"
      />  
      <OurTeamThree /> 
      <TabsIconSection title="What We Offer" tagline="We Turn Heads" />  */}
    
     
      
      <ClientsBrand classAppend="mt-50">
        <HeadingSection title="Clientes" />
      </ClientsBrand>
      <FooterOne />
    </Loader>
  );
};

export default CorporateBusiness;
