import React from "react";
import dataFeatures from "../../data/About/about-corporate-business.json";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import FeatureBoxTwo from "../../elements/FeatureBox/FeatureBoxTwo";

const WhoWeAreThree = () => (
  <section>
    <div className="container">
      <div className="row">
        <HeadingSection title="GRUPO INTEGRAL LISHI S.A.S." tagline="CONÓCENOS">
        Asesoría, Consultoría, Gestoría y Elaboración de Estudios, documentos, formatos, trámites, manuales, procedimientos, diagnósticos para cumplir con las siguientes dependencias del gobierno
        </HeadingSection>
      </div>
      <div className="row mt-50">
        {dataFeatures.map((feature, i) => (
          <FeatureBoxTwo
            key={feature.id}
            icon={feature.icon}
            title={feature.title}
            index={i}
          >
            {feature.text}
          </FeatureBoxTwo>
        ))}
      </div>
    </div>
  </section>
);

export default WhoWeAreThree;
