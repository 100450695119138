import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreEight from "../../components/WhoWeAre/WhoWeAreEight";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";


import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";


const array=[
  {
    "id": 1,
    "title": "Ingeniería Civil",
    "tagline": "",
    "image": "Servicios/ingcivil.jpg",
    "buttons": [
      {
        "id": 1
       
      }
    ]
  }
]

const ServiceSimple = () => (
  <Loader>
    <HeaderWithoutDrop />
    <HeroSliderTwo data={array} />
    {/*<PageTitleService title="Proteccion Civil" tagline="Ideas that soar" />*/}
    <section>
      <div className="container">
          <div className="row">
          <div className="col-md-4 mb-20 col-sm-4">
           <ul>
             <li>Laboratorio de materiales</li>
             <li>Estudios y proyectos</li>
             <li>Construcción de obra civil</li>
             <li>Geotecnia</li>
             <li>Cimentaciones profundas</li>
             <li>Supervisión de obra</li>
             <li>Levantamientos topográficos (Altimetría y planimetría) </li>
             <li>Levantamientos Urbanos, Rurales, escurrimientos naturales, obras de captación, etc.</li>
             <li>Control topográfico de Obra (trazo de ejes de proyecto, cálculo de volúmenes de obra, replanteo de puntos, etc.).</li>
           </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <ul>
          <li>Elaboración de planos y proyectos ejecutivos de casa habitación.</li>
          <li>Cálculo y diseño estructural de Edificios en concreto y Acero.</li>
          <li>Cálculo y diseño estructural de Naves Industriales.</li>
          <li>Cálculo y diseño estructural de pasos inferiores y pasos superiores vehiculares.</li>
          <li>Análisis y diseños de techumbres, gasolineras, anuncios espectaculares, torres de telecomunicaciones</li>
          <li>Proyectos ejecutivos de terracerías de vialidades y caminos.</li>
          <li>Proyectos ejecutivos de terracerías de Desarrollos de Vivienda urbana.</li>
          </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
            <ul>
              <li>Proyectos ejecutivos de terracerías de entronques a nivel, pasos superiores  y pasos inferiores vehiculares</li>
              <li>Proyectos ejecutivos de terracerías de plataformas para naves industriales y centros comerciales</li>
              <li>Proyectos ejecutivos de terracerías en muros de contención.</li>
              <li>Estudios de impacto vial</li>
              <li>Constancias de seguridad estructural.</li>
              <li>Estudios de impacto Ambiental</li>
              <li>Estudios de Mecánica de suelos</li>
              <li>Estudios Hidrológicos</li>
            </ul>
          </div>
         
        </div>
      </div>
    </section>
   

    <FooterOne />
  </Loader>
);

export default ServiceSimple;
