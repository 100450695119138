import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreEight from "../../components/WhoWeAre/WhoWeAreEight";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";

import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";


const array=[
  {
    "id": 1,
    "title": "Jurídico",
    "tagline": "",
    "image": "Servicios/law1.jpg",
    "buttons": [
      {
        "id": 1
       
      }
    ]
  }
]


const ServiceSimple = () => (
  <Loader>
    <HeaderWithoutDrop />
    <HeroSliderTwo data={array} />
    {/*<PageTitleService title="Proteccion Civil" tagline="Ideas that soar" />*/}
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-20 col-sm-4">
           <ul>
             <li>Declaración de apertura.</li>
             <li>Asesoría, Gestoría de demandas Ciudadanas (PAOT).</li>
             <li>Asesoría, Gestoría ante el INVEA.</li>
             <li>Vo.Bo de Seguridad y operación.</li>
           </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <ul>
          <li>Vo.Bo estructural, eléctrico</li>
          <li>Vo.Bo Eléctrico </li>
          <li>Licencia de construcción.</li>
          <li>Licencia de Funcionamiento Federal y Estatal.</li>
          </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
            <ul>
              <li>Permisos para anuncios, espectaculares y marquesinas.</li>
              <li>Uso de suelo.</li>
              <li>Destrucción de archivo muerto.</li>
              <li>Destrucciones fiscales (incineración, trituración, etc. y de re-uso dividido.)</li>
            </ul>
          </div>
         
        </div>
      </div>
    </section>
   
    <FooterOne />
  </Loader>
);

export default ServiceSimple;
