import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import PageTitleService from "../../components/PageTitle/PageTitleService";
import WhoWeAreEight from "../../components/WhoWeAre/WhoWeAreEight";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";


import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";


const array=[
  {
    "id": 1,
    "title": "Medio Ambiente",
    "tagline": "",
    "image": "Servicios/medioAmbiente.jpg",
    "buttons": [
      {
        "id": 1
       
      }
    ]
  }
]


const ServiceSimple = () => (
  <Loader>
    <HeaderWithoutDrop/>
    <HeroSliderTwo data={array} />

    {/*<PageTitleService title="Proteccion Civil" tagline="Ideas that soar" />*/}
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-20 col-sm-4">
           <ul>
             <li>Servicios de asesoría, consultoría, gestoría.</li>
             <li>Estudios de Impacto Ambiental como : Agua, Ruido, Emisiones a la atmosfera</li>
             <li>Bitácoras, manuales, programas, formatos, trámites, contestación de escritos, presentación de pruebas, etc.</li>
             <li>Impugnación de multas y sanciones emitidas.</li>
             <li>Licencia ambiental Única (DF y Federal).</li>
             <li>Cédula de operación integral..</li>
           </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
          <ul>
          <li>Referendum anual de normas aplicables al área laboral.</li>
          <li>Referendum de alcantarillado y descargas de agua.</li>
          <li>División de residuos reciclables (papel, cartón, vidrio y plástico).</li>
          <li>Recolección de residuos sólidos peligrosos.</li>
          <li>Succión de aceite sucio y otros líquidos peligrosos.</li>
          <li>Análisis de emisiones.</li>
          </ul>
          </div>
          <div className="col-md-4 mb-20 col-sm-4">
            <ul>
              <li>Caracterización de residuos (C. R. E. T. I.B)</li>
              <li>Caracterización y remediación de sitios contaminados.</li>
              <li>Impacto y riesgo ambiental.</li>
              <li>Control de plagas.</li>
              <li>Desazolves.</li>
              <li>Desinfección de áreas de aseo y alimenticias.</li>
              <li>Fumigación.</li>
            </ul>
          </div>
         
        </div>
      </div>
    </section>
   

    <FooterOne />
  </Loader>
);

export default ServiceSimple;
