import React from "react";
import logoFooter from "../../assets/images/grupoLishiTransparent.png";
import FooterCopyright from "./FooterCopyright";

const FooterOne = () => (
  <>
    <footer className="footer" id="footer-fixed">
      <div className="footer-main">
        <div className="container">
          <div className="row">
           {/* <div className="col-sm-6 col-md-4">
            <img
                      className="logos logo-displays"
                      src={logoFooter}
                      alt="200px"
                      height="400px"
            />  
           
            </div>*/}
           {/* <div className="col-sm-6 col-md-2">
              
              <div className="widget widget-links">
                <h5 className="widget-title">Work With Us</h5>
                <ul>
                  <li>
                    <a href="#!">Themeforest</a>
                  </li>
                  <li>
                    <a href="#!">Audio Jungle</a>
                  </li>
                  <li>
                    <a href="#!">Code Canyon</a>
                  </li>
                  <li>
                    <a href="#!">Video Hive</a>
                  </li>
                  <li>
                    <a href="#!">Envato Market</a>
                  </li>
                </ul>
              </div> 
            </div> */}
            <div className="col-sm-6 col-md-2"></div>
            <div className="col-sm-6 col-md-2"></div>

            <div className="col-sm-6 col-md-2">
              <div className="widget widget-links">
                <h5 className="widget-title">Navegacion</h5>
                <ul>
                  <li>
                    <a href={process.env.PUBLIC_URL+"/stps"}>S.t.p.s</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL+"/proteccion-civil"}>Proteccion Civil</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL+"/medio-ambiente"}>Medio Ambiente</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL+'/ingenieria-civil'}>Ingeniería civil</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL+'/ingenieria-industrial'}>Ingeniería Industrial</a>
                  </li>
                  <li>
                    <a href={process.env.PUBLIC_URL+'/juridico'}>Jurídico</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text widget-links">
                <h5 className="widget-title">Contáctanos</h5>
                <ul>
                  <li>
                    <i className="icofont icofont-iphone"></i>
                    ING. LEONEL MENDOZA HERNÁNDEZ<br></br>
                    <a href="tel:5514084480">5514084480</a><br></br>
                    <a href="tel:5576029924">5576029924</a>
                  </li>
                  <li>
                    <i className="icofont icofont-iphone"></i>
                    ING. CESAR MENDOZA GARCIA<br></br>
                    <a href="tel:5620087182">5620087182</a>
                  </li>
                 <li>
                    <i className="icofont icofont-mail"></i>
                    <a href="mailto:atencionaclientes@grupointlishi.com.mx">
                    atencionaclientes@grupointlishi.com.mx
                    </a>
                  </li> 
                  {/*} <li>
                    <i className="icofont icofont-globe"></i>
                    <a href={process.env.PUBLIC_URL}>www.envato.com</a>
                  </li>*/} 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
    <div className="footer-height" style={{ height: "463px" }}></div>
  </>
);

export default FooterOne;
