import React from "react";
import dataServices from "../../data/Services/services-seo-data.json";
import Icofont from "react-icofont";

const WhoWeAreFive = () => (
  <section>
    <div className="container">
      <div className="row">
          <div
            className="col-md-3 feature-box text-center col-sm-6"
            data-aos={"zoom-in"}
            data-aos-delay={`${1}00`}
            data-aos-duration={700}
          >
            <Icofont
              icon="man-in-glasses"
              className={
                "font-40px light-icon circle-icon fade-icon " + "yellow-bg-icon fade-icon-yellow"
              }
            />
            <p><ul>
              <li>Servicios de asesoría, consultoría, gestoría</li>
              <li>Elaboración de todos los requerimientos: Estudios, pruebas, bitácoras, manuales, programas, formatos, trámites, contestación de escritos, presentación de pruebas, etc. que esta dependencia solicita presentar.</li>
            </ul>
            </p>
          </div><br></br>
          <div
            className="col-md-3 feature-box text-center col-sm-6"
            data-aos={"zoom-in"}
            data-aos-delay={`${2}00`}
            data-aos-duration={700}
          >
            <Icofont
              icon="computer"
              className={
                "font-40px light-icon circle-icon fade-icon " + "yellow-bg-icon fade-icon-yellow"
              }
            />
            <p><ul>
              <li>Implementación de Sistemas de Seguridad e Higiene.</li>
              <li>Cursos de capacitación en seguridad e higiene laboral.</li>
              <li>Impugnación de multas y sanciones emitidas.</li>
            <li>Atención de visitas de inspección.</li>
            </ul>
            </p>
          </div>
          <div
            className="col-md-3 feature-box text-center col-sm-6"
            data-aos={"zoom-in"}
            data-aos-delay={`${3}00`}
            data-aos-duration={700}
          >
            <Icofont
              icon="chart-bar-graph"
              className={
                "font-40px light-icon circle-icon fade-icon " + "yellow-bg-icon fade-icon-yellow"
              }
            />
            <p><ul>
              <li>Autorización de funcionamiento de recipientes sujetos a presión: compresores, tanques, hidroneumáticos, tanques de nitrógeno , marmitas, tanques de almacenamiento, etc. y Calderas.</li>
              <li>Pruebas hidrostáticas , neumáticas y ensayos no destructivos</li>
            <li>Diagramas unifilares.</li>
            </ul>
            </p>
          </div>
          <div
            className="col-md-3 feature-box text-center col-sm-6"
            data-aos={"zoom-in"}
            data-aos-delay={`${4}00`}
            data-aos-duration={700}
          >
            <Icofont
              icon="abacus-alt"
              className={
                "font-40px light-icon circle-icon fade-icon " + "yellow-bg-icon fade-icon-yellow"
              }
            />
            <p><ul>
              <li>Estudios de: Ruido, Polvos, Iluminación, Vibraciones, Condiciones térmicas elevadas o abatidas , Electricidad estática.</li>
              <li>Estudios de grado de riesgo.</li>
            <li>Auditorias y diagnósticos situacionales.</li>
            </ul>
            </p>
          </div>
      </div>
    </div>
  </section>
);

export default WhoWeAreFive;
