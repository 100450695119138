import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FsLightbox from "fslightbox-react";
import Loader from "../../components/Loader/Loader";
import dataSlider from "../../data/Slider/stpsheader.json";
import HeaderWithoutDrop from "../../components/Header/HeaderWithoutDrop";
import HeroSliderTwo from "../../components/Hero/HeroSliderTwo";
import WhoWeAreStps from "../../components/WhoWeAre/WhoWeAreStps";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";

const SEOAgency = () => {
  const [toggler, setToggler] = useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=sU3FkzUKHXU"]}
      />
      <HeaderWithoutDrop/>
      <HeroSliderTwo data={dataSlider} />
      <WhoWeAreStps />
  
 
      <FooterOne />
    </Loader>
  );
};

export default SEOAgency;
